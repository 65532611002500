// client/AppContext.js
import React, { createContext, useState } from 'react';
import AssetLib from "./assets/AssetLib";

const AppContext = createContext();


const selfieUrl = (window.__selfieUrl != '') ? window.__selfieUrl : undefined;
const mode = (window.__mode);

const INIT_STATE = { selfieUrl, mode };
const AppContextProvider = ({ children }) => {
   const [app, setApp] = useState(INIT_STATE);

   return (
      <AppContext.Provider value={{ app, setApp }}>
         {children}
      </AppContext.Provider>
   );
};
const AppContextConsumer = AppContext.Consumer;
export { AppContext, AppContextProvider, AppContextConsumer };
export default AppContext;
