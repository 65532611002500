import React, { useContext } from 'react';
import '../styles/SelfieView.css'
import AppContext from '../AppContext';
import AssetLib from '../assets/AssetLib';

const SelfieView = ({ children }) => {
   const { app } = useContext(AppContext);
   const { selfieUrl, mode } = app;

   console.log("Selfie URL is " + selfieUrl);
   console.log("mode is " + mode);

   return (

       <div class= "view-container">
        <div class="view-mode-wrapper" style={(mode === 'view') ? {display:"flex"} : {display:"none"}}>
            <div class="view-header">
                    <img src={AssetLib["header-img"]} />
                </div>
            <div class="selfie-view-container hidden-view" style= {(selfieUrl === undefined || selfieUrl === '') ? {display:"none"} : {display:"flex"}} > 
            
                <div class="view-image"><img src= {  (mode === "view") ? selfieUrl : ''  }/></div>
                <div class="view-instructions"><img 
                    onLoad={() => { document.querySelector('.selfie-view-container').classList.remove('hidden-view'); console.log("Loaded");}}
                    onError={(err) => { console.log("Errored");}}
                    src={AssetLib["interact-icon"]}
                /></div>
                <div class="view-instructions-text"><p>Tap+Hold or Right Click the image to save</p></div>
                <div class="view-portrait-img"> <img src={AssetLib["art-pattern"]}/></div>
                <div class="view-legal"><p></p></div>
            </div>

            <div class="view-error" style= {(selfieUrl === undefined || selfieUrl === '') ? {display:"block"} : {display:"none"}}>
                <div class="view-error-header">SORRY, YOUR PHOTO EXPIRED</div>
                <div class="view-error-subheader">Please revisit our Legacy Store in Plano to retake.</div>
            </div>
        </div>
        <div class="print-mode-wrapper" style={(mode === 'print') ? {display:"flex"} : {display:"none"}}>
            <div class="view-image-print"><img src= { (mode === "print") ? selfieUrl : '' }/></div>
            <div class="view-error" style= {(selfieUrl === undefined || selfieUrl === '') ? {display:"block"} : {display:"none"}}>
                <div class="print-view-error-header">THIS PHOTO HAS EXPIRED OR IS INVALID</div>
            </div>
        </div>
        </div>
   );
};
export default SelfieView;


