import React from 'react'
import '../styles/App.css'
import { AppContextProvider } from '../AppContext'
import SelfieView from '../components/SelfieView'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom';

      

class App extends React.Component {



  constructor(props){
    super(props);

  
    this.state ={
      title: 'Customer Appreciation',
      sessionId:"",
      socketParams:null,
      socket: null,
      bConnectionEstablished:true,
      statusMessage: "Awaiting connection to server"
    };
  }
  
  

  componentDidMount(){
  
  }

  render() {

    const trackingId = "UA-71671299-20"; //19 for dev 20 for prod
    const pageName = 'microsite-customer pop in';

    ReactGA.initialize(trackingId);
    ReactGA.ga(function(tracker) {
      var clientId = tracker.get('clientId');
      ReactGA.set({ dimension1: clientId });
      ReactGA.set( {dimension3: pageName });
      let titlev = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
      ReactGA.pageview(titlev);
    });

    return(
      <AppContextProvider>
        <div className="App">
          <SelfieView/>
        </div>     
      </AppContextProvider>
    );
  }
}

export default App;
